import axios from 'axios';
import {ElMessage} from 'element-plus'

const instance = axios.create({
  baseURL: "https://daka-api.tillcn.com",
  timeout: 999999999,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
    config.headers = {
      'Content-Type': 'application/json',
      'x-token': "tkplus.agent.request"
    }
    return config
  },
  error => {
    console.log(error)
  });

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const {data} = response;
      if (data.code === 0) {
        return data;
      } else {
        ElMessage.error(data["msg"])
        return Promise.reject(data['msg'])
      }
    } else {
      ElMessage.error(response.statusText)
      return Promise.reject("网络错误")
    }
  },
  (err) => {
    const {config} = err;
    if (!config || !config.retry) return Promise.reject(err);
    config.retryCount = config.retryCount || 0;
    if (config.retryCount >= config.retry) {
      return Promise.reject(err);
    }
    config.retryCount += 1;
    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, config.retryDelay || 1);
    });

    return backoff.then(() => instance(config));
  },
);

export default instance;
