<template>
  <el-form
      ref="ruleFormRef"
      :model="form"
      label-width="60px"
      class="demo-ruleForm"
      status-icon
  >
    <el-form-item label="密码" prop="name">
      <el-input v-model="form.password" placeholder="密码"/>
    </el-form-item>

    <el-form-item label="姓名" prop="username">
      <el-select v-model="form.username" style="width: 100%" placeholder="请选择姓名">
        <el-option label="李峰" value="e71d8a8g"/>
        <el-option label="陈长云" value="9fac2ba9"/>
        <el-option label="李经珊" value="9db27e5a"/>
        <el-option label="林晋" value="63dfg9a5"/>
        <el-option label="潘昭彤" value="5e54dc4d"/>
      </el-select>
    </el-form-item>

    <el-form-item label="上/下午" prop="region">
      <el-select v-model="form.period" style="width: 100%" placeholder="上午或下午">
        <el-option label="上午" value="AM"/>
        <el-option label="下午" value="PM"/>
      </el-select>
    </el-form-item>

    <el-form-item prop="date1" label="日期">
      <el-date-picker
          v-model="form.date"
          type="date"
          label="日期"
          placeholder="日期"
          style="width: 100%"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
      />
    </el-form-item>

    <el-form-item prop="time1" label="时间">
      <el-time-picker
          v-model="form.time"
          style="width: 100%"
          placeholder="不选择则随机"
          format="HH:mm:ss"
          value-format="HH:mm:ss"
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submit">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  name: 'App',
}
</script>

<script setup>
import {reactive} from "vue";
import {attendance} from "@/api/attendance";
import {ElMessage} from "element-plus";

const form = reactive({
  username: "e71d8a8g",
  password: "",
  period: "",
  date: '',
  time: ""
})

const submit = () => {
  attendance(form).then(res => {
    ElMessage.success(res["msg"])
  })
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
